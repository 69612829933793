function finishSliders() {
	$('.instagram-pics').slick({
		slidesToScroll: 1,
		arrows: false,
		infinite: true,
		slidesToShow: 10,
		centerMode: true,
		autoplay: true,
		autoplaySpeed: 2000,
		responsive: [
		{
			breakpoint: 1700,
			settings: {
				slidesToShow: 5,
			}
		},
		{
			breakpoint: 478,
			settings: {
				slidesToShow: 1,
				centerMode: false,
				slidesPerRow: 2,
				rows: 3,
			}
		}]
	});

	var heroSlides = $('.hero-slider .slide__body');

	$(heroSlides).each(function(i,slide) {

		var locations = $(slide).data('locations');

		if (locations.length) {
			// Maybe hide

			if (window.data.myLocation.id === 0) {
				// User doesn't have a location
				$(slide).remove();
			} else if (locations.indexOf(window.data.myLocation.id) < 0) {
				// User's location isn't in this valid list
				$(slide).remove();
			}
		} else {
			// Don't hide
		}
	});

	$('.hero-slider').slick({
		infinite: true,
		dots: true,
		autoplay: true,
		autoplaySpeed: 5000
	});

	$('.reviews-slider').slick({
		infinite: true,
		arrows: true,
		nextArrow: '<button class="slick-next"></button>',
		prevArrow: '<button class="slick-prev"></button>',
		responsive: [
		{
			breakpoint: 1700,
			settings: {
				dots: false,
			}
		},
		{
			breakpoint: 920,
			settings: {
				dots: true,
			}
		}]
	});

	$('.awards-slider').slick({
		slidesToScroll: 1,
		arrows: false,
		infinite: true,
		slidesToShow: 6,
		centerMode: true,
		autoplay: true,
		autoplaySpeed: 2000,
		responsive: [
		{
			breakpoint: 478,
			settings: {
				slidesToShow: 2,
				centerMode: true,
			}
		}]
	});
}
$(document).ready(function () {
	if ($('.awards-slider').length > 0 || $('.hero-slider').length > 0 || $('.instagram-pics').length > 0 || $('.reviews-slider').length > 0) {
		requireStyle(contentUrl+'/themes/xoo-boilerplate/dist/css/vendor/slick.min.css');
		requireScript(contentUrl+'/themes/xoo-boilerplate/dist/js/vendor/slick.min.js', finishSliders);
	}
});